import React, {useEffect, useState, useRef} from 'react';
import './SpeechToText.css'; // Import CSS file for toggle switch styling
import recordGif from '../assets/record.gif';
import sonarSound from '../assets/sonar.mp3';
import AIloading from '../assets/loading.gif';
import audioplaying from '../assets/audio.webp';

const SpeechToText = () => {
  const [transcription, setTranscription] = useState([]);
  const [ws, setWs] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeechMode, setIsSpeechMode] = useState(true);
  const [textInput, setTextInput] = useState('');
  const [isPlaying, setIsPlaying] = useState(false); // Add state for playing audio
  const [recordingChunks, setRecordingChunks] = useState([]);
  const chatContainerRef = useRef(null);
  const audioContextRef = useRef(null); // Reference for AudioContext
  const audioQueueRef = useRef([]); // Queue for audio data
  const [audioChunks, setAudioChunks] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [waiting, setWaiting] = useState(false);
  const [ended, setEnded] = useState(false);
  const [startTime, setStartTime] = useState(null); // Start time state
  const [dontSendAudio, setDontSendAudio] = useState(false);
  const isFirstLoad = useRef(true);

  useEffect(() => {
    console.log("Waiting changesd to: ", waiting);
  }, [waiting])
  useEffect(() => {
    const socket = new WebSocket('wss://iqvate.com');
    // const socket = new WebSocket('ws://localhost:3001');
    setWs(socket);
    socket.binaryType = 'arraybuffer';

    // Create AudioContext instance
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

    socket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    socket.onmessage = async (event) => {
      console.log('WebSocket message received:', event.data, typeof event.data);
      const data = event.data;
      if (typeof data === 'string') {
        const parsedData = JSON.parse(data);
        console.log('parsedData: ', parsedData);
        if (parsedData && parsedData.type === 'server') {
          setLoading(false);
          if (parsedData.vis) {
            setTranscription((prev) => [...prev, {
              text: parsedData.data.text,
              isServer: true,
              vis: parsedData.data.emoji
            }]);
          } else {
            setTranscription((prev) => [...prev, {text: parsedData.text, isServer: true}]);
          }
        } else if (parsedData && parsedData.type === 'user') {
          setTranscription((prev) => [...prev, {text: parsedData.text, isServer: false}]);
        }
        setWaiting(!!parsedData.wait);
        setEnded(!!parsedData.end);
      } else if (typeof data === 'object' && isSpeechMode) {
        setLoading(false);
        await handleAudioData(data);
        // await handleAudioStreamingData(data);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
      console.log('WebSocket connection cleaned up');
    };
  }, []);  // Add dependencies as necessary

  const handleAudioData = async (audioChunk) => {
    console.log("recvdAudioChunk");
    setAudioChunks((prevChunks) => [...prevChunks, audioChunk]); // Store audio chunks

    try {
      // Decode audio data and play it
      const audioContext = audioContextRef.current;

      // Handle state changes in AudioContext
      audioContext.onstatechange = () => {
        if (audioContext.state === 'running') {
          console.log("AudioContext is running, audio has started playing");
          setIsPlaying(true); // Audio is playing
        } else {
          setIsPlaying(false);
        }
      };

      // Check if the audio context is in suspended state before playing
      if (audioContext.state === 'suspended') {
        console.log('AudioContext is suspended, trying to resume if its not first load');
        if(!isFirstLoad.current) {
          await audioContext.resume();
          console.log('AudioContext state after resume:', audioContext.state);
        } else {
          isFirstLoad.current = false;
          return;
        }
      }

      const audioBuffer = await audioContext.decodeAudioData(audioChunk);
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.onended = () => setIsPlaying(false);
      await source.start();
      setIsPlaying(true);
      console.log('AudioContext state:', audioContext.state);
    } catch (error) {
      console.error('Error decoding or playing audio:', error);
      setIsPlaying(false);
    }
  };
  useEffect(() => {
    // Scroll to bottom of chat container whenever transcription updates
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [transcription]);

  const startRecording = async () => {
    // log start recording
    console.log("startRecording");
    setIsRecording(true);
    // Record the start time when recording begins
    let startTime = Date.now();
    setStartTime(startTime);

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        console.log("currentRefmediaRecorderset", mediaRecorder);
        // Initialize an array to store the chunks for the current recording
        const chunks = [];

        mediaRecorder.onstart = () => {
          console.log('MediaRecorder started');
          startTime = Date.now();
          setStartTime(startTime);
        };

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data); // Push each chunk of data to the array
          }
        };

        mediaRecorder.onstop = async () => {
          let end = Date.now();
          let duration = (end - startTime) / 1000;
          if (duration < 1) {
            console.log("Recording was too short, not sending audio data.");
            alert('Recording was too short, please try again.');
            setLoading(false);
            setRecordingChunks([]);
            return;
          }
          // log stop recording
          console.log("stopRecording");
          // Combine all the chunks into a single Blob when recording stops
          const audioBlob = new Blob(chunks, {type: 'audio/webm'});
          sendAudioData(audioBlob); // Send the single combined audio file
          setRecordingChunks([]); // Clear chunks after handling
        };

        mediaRecorder.start();
      } catch (err) {
        console.error('Error accessing microphone:', err);
      }
    } else {
      console.error('getUserMedia not supported on your browser!');
    }
  };

  const playSonarSound = () => {
    // log
    console.log("playSonarSound");
    return new Promise((resolve, reject) => {
      const audio = new Audio(sonarSound); // Use the sonar.mp4 file
      audio.play()
        .then(() => {
          console.log("Sonar sound played");
          resolve(); // Resolve the promise when the sound finishes playing
        })
        .catch((err) => {
          console.error("Error playing sonar sound:", err);
          reject(err);
        });

      // Optionally, you can listen for the `ended` event if needed
      audio.onended = () => {
        resolve(); // Resolve when playback is finished
      };
    });
  };

  const stopRecording = async () => {
    await playSonarSound();
    // log stoprecording
    setLoading(true);
    setIsRecording(false);
    setTimeout(() => {
      if (mediaRecorderRef.current) {
        console.log("Stopping mediaRecorder:", mediaRecorderRef.current);
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
        mediaRecorderRef.current = null;
      } else {
        console.error("mediaRecorderRef.current is null in stopRecording");
      }
    }, 500); // Delay the stop by 500ms
  };

  const sendAudioData = (audioBlob) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        ws.send(arrayBuffer);
      };
      reader.readAsArrayBuffer(audioBlob);
    }
  };

  const handleTextSubmit = () => {
    if (textInput.trim()) {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({type: 'user', text: textInput})); // Send as user message
        setWaiting(false);
      }
      setTranscription((prev) => [...prev, {text: textInput, isServer: false}]);
      setTextInput('');
    }
  };

  return (
    <div>
      {/* Toggle Switch */}
      <div className='toggle-Container'>
        <img
          src={require('../assets/message.png')}
          style={{marginRight: '14px'}}
          className='icon'
          alt='msg'
        />
        <label className="toggle-switch">
          <input
            type="checkbox"
            checked={isSpeechMode}
            onChange={() => setIsSpeechMode(!isSpeechMode)}
            disabled={isRecording}
          />
          <span className="slider round"></span>
        </label>
        <img src={require('../assets/mic.png')} className='icon' alt='mic'/>
      </div>

      {
        !isSpeechMode ?
          //   List of messages in text mode below
          (
            <div
              ref={chatContainerRef}
              style={{
                border: '1px solid #ccc',
                padding: '10px',
                height: '60vh',
                overflowY: 'auto', // Ensure scroll is enabled
                marginTop: '20px'
              }}
            >
              {transcription.map((msg, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: msg.isServer ? 'right' : 'left',
                    backgroundColor: msg.isServer ? '#e0f7fa' : '#ffffff',
                    padding: '5px',
                    borderRadius: '5px',
                    marginBottom: '5px',
                    maxWidth: '70%',
                    marginLeft: msg.isServer ? 'auto' : '0',
                    marginRight: msg.isServer ? '0' : 'auto'
                  }}
                >
                  {msg.vis? msg.vis : msg.text}
                </div>
              ))}
            </div>
          ) : (
            // IMPORTANT: Voice Mode
            <div className='Speach-container'>
              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  borderRadius: '5px',
                  marginBottom: '10px',
                  overflow: 'hidden', // Hide the overflowed content
                  textOverflow: 'ellipsis', // Show ellipses if the text is too long
                  WebkitLineClamp: 5, // Show up to 5 lines before truncating
                  WebkitBoxOrient: 'vertical', // Required for line clamping to work
                }}
              >
                <div
                  style={{
                    fontSize: '80px'
                  }}
                >
                  {(transcription.slice().reverse().find(item => item.vis))?.vis}
                </div>
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'center', // Center the text within the div
                    whiteSpace: 'normal', // Ensure text wraps to fit within the container
                    overflowWrap: 'break-word', // Break long words to prevent overflow
                    width: '100%', // Ensure the text doesn't overflow the parent div
                  }}
                >
                  {(transcription.slice().reverse().find(item => item.vis))?.text}
                </div>
              </div>

              {/* Show latest message (always display the latest message) */}
              {transcription.length > 0 && (
                <>
                  {/* Check if the last message is from the system */}
                  {transcription[transcription.length - 1]?.isServer !== false ? (
                    <div style={{
                      flexGrow: 1,
                      display: 'flex',
                      with: '100%',
                      height: '100%',
                      maxHeight: '100%',
                      minHeight: '0',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      {/* Show latest system message and its corresponding vis: true if exists */}
                      {(transcription[transcription.length - 1].vis) && (
                        <div
                          style={{
                            width: '80%',
                            textAlign: 'center',
                            fontSize: '16px',
                            color: '#333',
                            padding: '10px',
                            borderRadius: '5px',
                            marginBottom: '10px',
                            maxHeight: '100%',
                            overflow: 'auto', // Hide the overflowed content
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {transcription[transcription.length - 2].text}
                        </div>
                      )}
                      {!(transcription[transcription.length - 1].vis) && (
                        <div
                          style={{
                            width: '80%',
                            textAlign: 'center',
                            fontSize: '16px',
                            color: '#333',
                            maxHeight: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            marginBottom: '10px',
                            overflow: 'auto', // Hide the overflowed content
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {transcription[transcription.length - 1].text}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        flexGrow: 1,
                        display: 'flex',
                        with: '100%',
                        height: '100%',
                        maxHeight: '100%',
                        minHeight: '0',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          width: '80%',
                          textAlign: 'center',
                          fontSize: '16px',
                          color: '#333',
                          maxHeight: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          marginBottom: '10px',
                          overflow: 'auto', // Hide the overflowed content
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {/* Show the latest user message */}
                        <b>You Said: </b>{transcription[transcription.length - 1]?.text}
                      </div>
                    </div>
                  )}
                </>
              )}
              <div style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
              }}>
                {/* Button is hidden during loading or playing, but transcript stays */}
                {waiting && !loading && !isPlaying && (
                  <>
                    <div
                      onMouseDown={startRecording}
                      onMouseUp={stopRecording}
                      style={{
                        width: '8em',
                        height: '8em',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px',
                        backgroundColor: '#f0f0f0',
                        cursor: 'pointer',
                        userSelect: 'none',
                        border: '2px solid #007bff',
                      }}
                    >
                      {isRecording ? (
                        <img src={recordGif} alt="Recording..." style={{width: '100%', height: '100%'}}/>
                      ) : (
                        <div style={{
                          width: '50px',
                          height: '50px',
                          backgroundColor: '#007bff',
                          borderRadius: '50%'
                        }}>
                        </div>
                      )}
                    </div>
                    {!isRecording ? (<div style={{paddingTop: '20px'}}>Tap and Hold To Speak</div>) : (
                      <div style={{paddingTop: '20px'}}>Recording...</div>)}
                  </>
                )}
                {isPlaying && (
                  <>
                    <div
                      style={{
                        width: '8em',
                        height: '8em',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '16px',
                        backgroundColor: '#f0f0f0',
                        cursor: 'pointer',
                        userSelect: 'none',
                        border: '2px solid #007bff',
                      }}
                    >
                      <img src={audioplaying} alt="Recording..." style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div style={{paddingTop: '20px'}}>Playing...</div>
                  </>
                )}
                {/* AI Loading Image */}
                {(!isPlaying && (loading || !waiting)) && (
                  <div style={{
                    flexGrow: 1, // Allow the div to grow and take available space
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <img src={AIloading} alt="Loading..." style={{width: '50%', height: '50%', objectFit: 'contain'}}/>
                  </div>
                )}
              </div>
            </div>
          )
      }
      {/* Text Mode Chat Textfield Below */}
      {
        !isSpeechMode ? (
          waiting ? (
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '2em',
                overflow: 'hidden',
                border: '1px solid #ccc',
                width: '90%',
                marginLeft: '5%'
              }}
            >
              <input
                disabled={!waiting}
                type="text"
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    // Perform action when Enter is pressed
                    handleTextSubmit();
                  }
                }}

                placeholder="Type your message..."
                style={{
                  padding: '10px',
                  fontSize: '16px',
                  outline: 'none',
                  borderRadius: '2em 0 0 2em',
                  flexGrow: 1,
                  border: '2px solid gray'
                }}
              />
              <button
                disabled={!waiting}
                onClick={handleTextSubmit}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: '#2196F3',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: '0 2em 2em 0',
                  border: '2px solid #2196F3'
                }}
              >
                Send
              </button>
            </div>
          ) : (
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '90%',
                marginLeft: '5%'
              }}
            >
              <p>{ended ? 'Ended' : 'Loading...'}</p>
            </div>
          )
        ) : null}


    </div>
  );
};

export default SpeechToText;
