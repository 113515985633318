import React from 'react';
import './App.css';
import SpeechToText from './component/SpeechToText'; // Assuming you have a component named SpeechToText

function App() {
  return (
    <div className="device">
    <div className="status">
      <div className="time">9:41</div>
      <div className="blank" />
      <div className="icons">
        <iconify-icon icon="ic:round-signal-cellular-alt" inline="" />
        <iconify-icon icon="ic:round-wifi" inline="" />
        <iconify-icon icon="gg:battery-full" inline="" />
      </div>
    </div>
    <div className="floating">
      <div className="camera">
        <div className="blob white" />
        <div className="blob bbg" />
        <div className="blob green" />
        <div className="blob red" />
      </div>
    </div>
    <div className="home" />
    <SpeechToText />
    <div />
  </div>
  
  );
}

export default App;
